import type { UserOverview } from '~/common/types/user-overview';
import { UserRole, VipStatus } from '~/models/query-types';
import type { UserResponse } from '~/repositories/query-repository';

export const convertQueryResponseToUsers = (
  response: UserResponse[]
): UserOverview[] => {
  return response
    .filter((u) => {
      // ストーリーに紐づくユーザがあり、ユーザがBANされていたらリストに表示しない
      if (u?.user_role === UserRole.USER_ROLE_BANNED) {
        return false;
      }

      return true;
    })
    .map((u) => convertIUserResponseToUserOverview(u));
};

export const convertIUserResponseToUserOverview = (
  userResponse: UserResponse
): UserOverview => {
  return {
    id: userResponse.id!,
    name: userResponse.name ?? '',
    profile: userResponse.profile ?? null,
    thumbnailUrl: userResponse.thumbnail?.serving_url ?? null,
    coverImageUrl: userResponse.cover_image?.serving_url ?? null,
    isOfficialWriter:
      userResponse?.user_role === UserRole.USER_ROLE_OFFICIAL_WRITER || false,
    isVip:
      userResponse.vip_status === VipStatus.VIP_STATUS_VIP ||
      userResponse.vip_status === VipStatus.VIP_STATUS_IN_TRIAL,
    searchableStoryCount: userResponse.searchable_story_count || null,
    followerCount: userResponse.follower_page?.cursor?.total_count || null,
    followeeCount: userResponse.followee_page?.cursor?.total_count || null,
  };
};
