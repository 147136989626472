import type { SnakeCasedPropertiesDeep } from 'type-fest';
import type { query_recursive_types } from '~/infra/api/rpc';

type UserRequireField =
  SnakeCasedPropertiesDeep<query_recursive_types.IUserRequireField>;

/**
 * build UserRequireField
 * @returns
 */
export const buildUser = (): UserRequireField => {
  return {
    require_id: true,
    require_name: true,
    require_vip_status: true,
    require_user_role: true,
    require_thumbnail: {
      require_id: true,
      require_serving_url: true,
    },
  };
};
