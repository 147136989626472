import { convertIGenreToGenre } from '~/common/converter/genre/genre';
import { convertToNgKeywordFilteredSeriesOverview } from '~/common/converter/ng-keyword/ng-keyword';
import { convertIUserResponseToUserOverview } from '~/common/converter/user/user';
import type { SeriesOverview } from '~/common/types/series-overview';
import type { SeriesOverviewWithRankingTrend } from '~/common/types/series-overview';
import type { Trend } from '~/common/types/trend';
import type { UserOverview } from '~/common/types/user-overview';
import type {
  SeriesResponse,
  SeriesRankingResponse,
} from '~/repositories/query-repository';
import { formatDateJapanese } from '~/utils/form';
import { convertISharedWithToSeriesAvailability } from './series-availability';

export const convertISeriesListToNgFilteredSeriesOverviewList = (
  seriesList: SeriesResponse[],
  ngKeywords: string[],
  user?: UserOverview // option for attaching existing user to SeriesOverview results
): SeriesOverview[] => {
  return seriesList
    .map((element) => convertISeriesResponseToSeriesOverview(element))
    .map((element) => {
      if (user) {
        element.user = user;
      }
      return convertToNgKeywordFilteredSeriesOverview(element, ngKeywords);
    });
};

export const convertISeriesResponseToSeriesOverview = (
  series: SeriesResponse
): SeriesOverview => {
  const id = series.id!;
  const thumbnailURL = series.thumbnail?.serving_url ?? null;
  const title = series.title ?? '';
  const description = series.description ?? '';
  const tags = (series.tags ?? []).map((tag) => tag!);
  const hasFreeStory = series.has_free_story || false;
  const isOfficial = series.is_official || false;
  const _publishedDate = series.latest_published_at ?? 0;
  const publishedAt = new Date(_publishedDate);
  const latestPublishedAtISOString = publishedAt.toISOString();
  const latestPublishedAtText = formatDateJapanese(publishedAt);
  const likeCount = series.total_like_count || 0;
  const _updatedAt = series.updated_at ?? 0;
  const updatedAt = new Date(_updatedAt);
  const updatedAtISOString = updatedAt.toISOString();
  const isCompleted = series.is_completed || false;
  const isOneshot = series.is_oneshot || false;
  const availability = convertISharedWithToSeriesAvailability(
    series.shared_with_status
  );
  const searchableStoryCount = series.searchable_story_count || 0;
  const recommenderModelId = series.recommender_model_id;

  let seriesGroup: SeriesOverview[] = [];
  const isNovel = series.has_novel_script_story || false;
  const canShowGoogleAd = series.can_show_google_ad || false;
  const hasSensitiveStories = series.is_sensitive || false;

  if (series.series_group_list && series.series_group_list[0]) {
    seriesGroup =
      series.series_group_list[0].series_list?.map((se) =>
        convertISeriesResponseToSeriesOverview(se)
      ) || [];
  }
  const genre = convertIGenreToGenre(series.genre || null);

  return {
    id,
    thumbnailURL,
    title,
    description,
    tags,
    user: series.user ? convertIUserResponseToUserOverview(series.user) : null,
    latestPublishedAtISOString,
    latestPublishedAtText,
    updatedAtISOString,
    likeCount,
    hasFreeStory,
    isOfficial,
    seriesGroup,
    isCompleted,
    isOneshot,
    availability,
    isNovel,
    canShowGoogleAd,
    hasSensitiveStories,
    searchableStoryCount,
    genre,
    recommenderModelId,
  };
};

/**
 * QueryServiceから取得したSeriesのリストをSeriesの配列に変換するConverter
 * @param response
 * @returns
 */
export const convertQueryResponseToSeries = (
  response: SeriesResponse[]
): SeriesOverview[] => {
  return response.map((s) => convertISeriesResponseToSeriesOverview(s));
};

export const convertISeriesResponseToSeriesOverviewWithRankingTrend = (
  seWithTrend: SeriesRankingResponse
): SeriesOverviewWithRankingTrend => {
  const series = convertISeriesResponseToSeriesOverview(
    seWithTrend.series as SeriesResponse
  );
  const trend = seWithTrend.trend as Trend;

  return {
    ...series,
    trend: trend,
  };
};
