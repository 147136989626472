import { useEffect, useState } from 'react';

/**
 * Modified from link below
 * !!!!NOTICE!!!!
 * This hooks stores MediaQuery values on the client side in useState to prevent Hydration Missmatch in SSR.
 * (Therefore, the value will be undefined in the initial rendering)
 * When the structure is such that a mismatch cannot be caused by SSR, use MediaQuery for CSS.
 * @see https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 * @param mediaQueryString
 * @returns {unknown}
 */
export const useClientOnlyMediaQuery = (mediaQueryString: string) => {
  const [matches, setMatches] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(!!mediaQueryList.matches);
    listener();
    mediaQueryList.addEventListener('change', listener); // updated from .addListener
    return () => mediaQueryList.removeEventListener('change', listener); // updated from .removeListener
  }, [mediaQueryString]);

  return matches;
};
