import { SeriesAvailability } from '~/common/types/series-availability';
import { types } from '~/infra/api/rpc';

export const convertISharedWithToSeriesAvailability = (
  sharedWith?: types.SharedWithStatus | null
): SeriesAvailability => {
  if (!sharedWith) {
    return SeriesAvailability.PUBLIC;
  }
  switch (sharedWith) {
    case types.SharedWithStatus.SHARED_WITH_FOLLOWER:
      return SeriesAvailability.FOLLOWER;
    case types.SharedWithStatus.SHARED_WITH_MUTUAL_FOLLOWER:
      return SeriesAvailability.MUTUAL_FOLLOWER;
    default:
      return SeriesAvailability.PUBLIC;
  }
};
