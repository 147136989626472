import type { SnakeCasedPropertiesDeep } from 'type-fest';
import {
  type query_recursive_types,
  query_search_types,
  story,
} from '~/infra/api/rpc';
import { buildPagination } from './pagination-query-builder';
import { buildStory } from './story-query-builder';
import { buildUser } from './user-query-builder';

type SeriesRequireField =
  SnakeCasedPropertiesDeep<query_recursive_types.ISeriesRequireField>;

/**
 * build SeriesRequireField for list item
 * @returns
 */
export const buildSeries = (): SeriesRequireField => {
  return {
    require_id: true,
    require_title: true,
    require_description: true,
    require_thumbnail: {
      require_serving_url: true,
    },
    require_updated_at: true,
    require_latest_published_at: true,
    require_tags: true,
    require_is_official: true,
    require_is_completed: true,
    require_has_vip_only_story: true,
    require_has_free_story: true,
    require_user_availability: true,
    require_shared_with_status: true,
    require_has_novel_script_story: true,
    require_is_sensitive: true,
    require_can_show_google_ad: true,
    require_user: buildUser(),
  };
};

interface SeriesWithStoriesArgs {
  page: number;
  limit: number;
}

export const buildSeriesDetail = (): SeriesRequireField => {
  return {
    ...buildSeries(),
    require_total_like_count: true,
    require_genre: true,
    require_series_group_list: {
      require_id: true,
      require_series_list: { ...buildSeries(), require_total_like_count: true },
    },
  };
};

export const buildSeriesStories = (
  args: SeriesWithStoriesArgs
): SeriesRequireField => {
  return {
    require_search_story: {
      params: {
        sort_by:
          query_search_types.SearchStorySortBy
            .SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC,
        filter: {
          filter_any_status: [story.StoryStatus.PUBLISH],
          include_sensitive: true,
        },
      },
      require_story_page: {
        require_story_list: buildStory(),
        page: buildPagination(args),
      },
    },
  };
};
