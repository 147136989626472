import { Genre } from '~/common/types/genre';
import { query_types as QueryTypes } from '~/infra/api/rpc';

export const convertIGenreToGenre = (
  rpcGenre: QueryTypes.Genre | null
): Genre | null => {
  if (!rpcGenre) return null;
  switch (rpcGenre) {
    case QueryTypes.Genre.GENRE_BL:
      return Genre.GENRE_BL;
    case QueryTypes.Genre.GENRE_COMEDY:
      return Genre.GENRE_COMEDY;
    case QueryTypes.Genre.GENRE_DRAMA:
      return Genre.GENRE_DRAMA;
    case QueryTypes.Genre.GENRE_FANFIC:
      return Genre.GENRE_FANFIC;
    case QueryTypes.Genre.GENRE_FANTASY:
      return Genre.GENRE_FANTASY;
    case QueryTypes.Genre.GENRE_HORROR_THRILLER:
      return Genre.GENRE_HORROR_THRILLER;
    case QueryTypes.Genre.GENRE_OTHER:
      return Genre.GENRE_OTHER;
    case QueryTypes.Genre.GENRE_ROMANCE:
      return Genre.GENRE_ROMANCE;
    case QueryTypes.Genre.GENRE_ROMANCE_FANTASY:
      return Genre.GENRE_ROMANCE_FANTASY;
    default:
      return Genre.GENRE_OTHER;
  }
};
